@use '@amzn/awsui-design-tokens/polaris.scss' as awsui;
@import '~@amzn/awsui-components-react/sass/constants';

.header {
    background-color: awsui.$color-background-home-header;
    color: awsui.$color-text-home-header-default;
    height: 60px;
}

.header .logo img {
    height: 30px;
}

.header .awsui-util-container {
    background-color: inherit;
    color: inherit;
    height: inherit;
    border: none;
    box-shadow: none;
}

.content-background {
    background-image: url('../img/content-bg.png');
    background-repeat: no-repeat;
    background-size: 100% 210px;
    min-height: 100vh;
}

.custom-spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.awsui-util-container.max-width-container {
    margin: 0 auto;
}

@media (min-width: 1000px) {
    .awsui-util-container.max-width-container {
        max-width: 1000px;
    }
}

.awsui-util-container.client-config-container {
    max-width: 400px;
}

@media (min-width: 805px) {
    .awsui awsui-column-layout .awsui-column-layout-columns-3 .awsui-util-container.app-container {
        max-width: 30%;
    }
}

@media (max-width: 804px) {
    .awsui awsui-column-layout .awsui-column-layout-columns-3 .awsui-util-container.app-container {
        margin-left: 0;
    }
}

.awsui-util-container.client-config-container.button {
    min-width: 269px;
}

.awsui-util-container.container-no-border {
    background-color: transparent;
    border: none;
    box-shadow: none;
}

@media only screen and (max-width: 600px) {
    .header {
        background-color: awsui.$color-background-home-header;
        color: awsui.$color-text-home-header-default;
        height: 50px;
    }

    .header .logo img {
      height: 25px;
    }
}